//
// Datepicker
//

// [dir="rtl"]{
//   .datepicker {
//     right: 0 !important;
//     left: auto;
//   }
// }

.datepicker {
  border: 1px solid var(--#{$prefix}border-color);
  padding: 8px;
  z-index: 999 !important;

  table {
    tr {
      th {
        font-weight: 500;
      }
      td {
        &.active,
        &.active:hover,
        .active.disabled,
        &.active.disabled:hover,
        &.today,
        &.today:hover,
        &.today.disabled,
        &.today.disabled:hover,
        &.selected,
        &.selected:hover,
        &.selected.disabled,
        &.selected.disabled:hover,
        span.active.active,
        span.active:hover.active {
          background-color: $primary !important;
          background-image: none;
          box-shadow: none;
          color: $white !important;
        }

        &.day.focused,
        &.day:hover,
        span.focused,
        span:hover {
          background: var(--#{$prefix}light);
        }

        &.new,
        &.old,
        span.new,
        span.old {
          color: var(--#{$prefix}gray-500);
          opacity: 0.6;
        }

        &.range,
        &.range.disabled,
        &.range.disabled:hover,
        &.range:hover {
          background-color: var(--#{$prefix}gray-300);
        }
      }
    }
  }
}

.table-condensed {
  > thead > tr > th,
  > tbody > tr > td {
    padding: 7px;
  }
}

.bootstrap-datepicker-inline {
  .datepicker-inline {
    width: auto !important;
    display: inline-block;
  }
}

// DATEPICKER

.datepicker-container {
  border: 1px solid $border-color;
  box-shadow: none;
  background-color: $dropdown-bg;

  &.datepicker-inline {
    width: 212px;
  }
}

.datepicker {
  color: var(--#{$prefix}text-muted);
  .datepicker-switch:hover,
  .next:hover,
  .prev:hover,
  tfoot tr th:hover {
    background: var(--#{$prefix}light);
  }
}

.datepicker-panel {
  > ul {
    > li {
      background-color: $dropdown-bg;
      border-radius: 4px;

      &.picked,
      &.picked:hover {
        background-color: rgba($primary, 0.25);
        color: $primary;
      }

      &.highlighted,
      &.highlighted:hover,
      &:hover {
        background-color: $primary;
        color: $white;
      }

      &.muted,
      &.muted:hover {
        color: var(--#{$prefix}gray-500);
        opacity: 0.6;
      }
    }

    &[data-view="week"] {
      > li {
        font-weight: $fw-medium;
      }

      > li,
      > li:hover {
        background-color: $dropdown-bg;
      }
    }
  }
}
