.tutorials-page-layout{
  .add-tutorial-button {
    background-color: var(--bs-primary);
    color: #fff;
    border-radius: 8px;
    border: none;
    padding: 0 15px;
    height: 35px;

    .ab-icons {
      margin-right: 5px;
    }
  }

  .add-category-button {
    border: 1px solid var(--bs-primary);
    background: #fff;
    color: var(--bs-primary);
    border-radius: 8px;
    padding: 0 15px;
    height: 30px;

    .ab-icons {
      margin-right: 5px;
    }
  }

} 

.tutorials-main-container {
    padding: 30px 50px;
    min-height: 90vh;
    margin-top: 0px;
    border-radius: 8px;
    box-shadow: var(--box-shadow-x-small);
    position: relative;
    z-index: 9 !important;
    background: none;

    .tutorial-menu-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      padding: 12px 15px;
      border-bottom: 1px solid #ddd;
    }
   
  
    .items-container, .tutorial-container {
     padding: 5px;

      .items-holder {
        cursor: pointer;
        background: #fff;
        border-radius: 8px;
        padding: 20px 20px;
        padding-right: 10px;

        &:hover {
          .tutorial-title {
            text-decoration: underline;
          }
        }
      }
    }
  
    .tutorial-title {
      font-size: 18px;
      line-height: 1.27;
      color: var(--text-color);
      &:hover {
        color: var(--primary);
      }
    }

    .items-container, .tutorial-container  {
        .category-menu-button {
            opacity: 0;
            color: #888;
            background: none;
            border: none;
            outline: none;
            font-size: 22px;
            padding: 0;
          }

          &:hover .category-menu-button{
            opacity: 1;
          }
    }

    .tutorial-card-wrapper {
        .tutorial-summary{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        cursor: pointer;
        margin-top: 10px;
        padding: 12px;
        border-radius: 8px;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
            box-shadow: 0px 10px 20px rgba(90, 94, 111, 0.1);
        }
    }

    .card-info-container {
       
        .right-icon {
            opacity: 0;
            margin-left: 8px;
            font-size: 11px;
            color: #777;
          }

          &:hover {
            .right-icon {
              opacity: 1;
            }

            .line-text {
              text-decoration: underline;
            }
          }
    }
}

.tutorials-details-container {
  background: #fff;
  padding: 50px 60px;

  .tutorial-title {
    font-size: 26px;
    font-weight: bold;
  }

  .text-content-container {

    .ql-container {
      border: none;
    }

    .ql-toolbar {
      display: none;
    }
    
    h1 {
      font-size: 22px
    }

    h2 {
      font-size: 20px
    }

    h3 {
      font-size: 18px
    }

    p {
      font-size: 16px;
    }

    h1, h2, h3, p {
      font-weight: 400;
      white-space: pre-wrap;
      // margin-bottom: 12px;
    }

    // br {
    //   display: none;
    // }
  }
}

.create-tutorial-container {
  background: #fff;
  padding: 30px;
  margin-top: 10px;

  .tutorial-content-draggable {
    
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.04);
    padding: 16px 16px 16px 0;
    border-radius: 8px;
    .drag-icon {
      opacity: 0;
      min-width: 16px;
      display: flex;
      justify-content: center;
    }
  
    &:hover {
      .drag-icon {
        opacity: 1
      }
    }
  }

  .category-select .react-select__option{
    &:hover {
      background: rgba(0, 0, 0, 0.03) !important;
    }
  }

  .action-button {
    background: #fff;
    border: 1px solid #777;
    color: #777;
    font-size: 13px;
    padding: 7px 8px;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);
    margin-top: 0;
  }

  .delete-button {
    margin-top: 6px;
    color: var(--bs-danger);
    border-color: var(--bs-danger);
  }

  .toggle-button-left, .toggle-button-right {
    height: 35px;
    width: 80px;
    background: #fff;
    color: #777;
    border: 1px solid var(--bs-primary)
  }

  .toggle-button-left{
    border-radius: 5px 0 0 5px;
  }
  .toggle-button-right{
    border-radius: 0 5px 5px 0;
    margin-left: -1px;
  }

  .text-content-container {
    position: relative;
    width: 100%;
    .ql-container {
      height: 400px;
      background: #fff;
      color: #444;

      h1 {
        font-size: 22px
      }
  
      h2 {
        font-size: 20px
      }
  
      h3 {
        font-size: 18px
      }
  
      p {
        font-size: 16px;
        
      }

      h1, h2, h3, p {
        font-weight: 500;
        white-space: pre-wrap;
      }
    }

    .text-edit-button {
      position: absolute;
      right: 50px;
      top: 0px;
      opacity: 0;
      z-index: 20;
    }

    &:hover {
      .text-edit-button {
        opacity: 1;
      }
    }
  }

  .ql-toolbar {
    background: #fff
  }

  .upload-image-button {
    height: 120px;
    width: 100%;
    border: 1px solid slateblue;
    border-radius: 5px;
    color: slateblue;
    background: #f0ebfe
  }

  .uploaded-image {
    width: 100%;
    height: 300px;
    margin: auto;
    object-fit: contain;
  }

  .image-edit-menu-button {
    position: absolute;
    right: 6px;
    top: 0px;
    opacity: 0;
  }

  .uploaded-image-container {
    position: relative;
    width: 100%;
    &:hover {
      .image-edit-menu-button {
        opacity: 1;
      }
    }
  }

  .video-content-container {
    position: relative;
    
    .video-edit-menu-button {
      position: absolute;
      right: 10px;
      top: 10px;
      opacity: 0;
      z-index: 20;
    }

    &:hover {
      .video-edit-menu-button {
        opacity: 1;
      }
    }
  
  }

  
}

.hidden-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}
.hidden-tutorials-container {
  
  margin-left: 20px;
}

.expanded-text-editor {
  position: fixed !important;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3) !important;
  padding: 50px;


  .ql-container {
    height: calc(100vh - 140px)!important
  }
}