
.contacts-import-table-container {
  background: #fff;
  position: relative;
  z-index: 3;

  thead {
    background: #eee;
  }

  th {
    min-width: 300px;
    width: 300px;
    box-shadow: inset 0 0 1px #aaa;
    padding: 10px !important;
  }

  td {
    background-color: #fff;
    padding: 10px;
  }

  .index-column {
    min-width: 80px;
    width: 80px;
  }

  tbody {
    tr {
      position: relative;
      z-index: 0;

      td {
        border: 1px solid #eee;
      }
    }
  }

  .table-danger {
    background-color: rgb(250, 184, 184);
  }
}

.inputs-container {
  position: relative;
}

.date-format-input {
  position: relative;
  z-index: 5;
  margin-bottom: 10px;
}

.dropdown-input {
  position: relative;
  z-index: 4;
  margin-bottom: 10px;
}