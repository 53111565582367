//
// Parsley
//

.error {
  color: $danger;
}

.parsley-error {
  border-color: $danger;
}

.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
  &.filled {
    display: block;
  }
  > li {
    font-size: 12px;
    list-style: none;
    color: $danger;
    margin-top: 5px;
  }
}
